@import "~simplebar/dist/simplebar.css";
@import "~highlight.js/styles/androidstudio.css";
// Vex Core
@import "./@vex/styles/core";

/*
  You can override any CSS Variable or style here
  Example:

  :root {
   --sidenav-background: #333333;
  }

  All possible variables can be found in @vex/styles/core or by simply inspecting the element you want to change
  in Chrome/Firefox DevTools
*/

/**
  Uncomment the below code and adjust the values to fit your colors, the application will automatically adjust
 */
:root {
  // --color-primary: blue;
  // --color-primary-contrast: white;

  // --color-accent: yellow;
  // --color-accent-contrast: black;

  // --color-warn: yellow;
  // --color-warn-contrast: black;
}
.afu-upload-btn {
  background-color: #5C77FF;
  padding: 8px;
  position: relative;
  top: 30px;
  border-radius: 5px;
  color: aliceblue;
}
.afu-select-btn {
 background-color: rgb(187, 184, 184);
  padding: 10px;
  position: relative;
  top: 13px;
  cursor: pointer;
}
.afu-constraints-info {
  position: relative;
  top: 20px;
}

.text-grey{
  color: #A7A7A7 !important;
}

.text-white{
  color: #ffffff !important;
}
